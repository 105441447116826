import React, { Component } from 'react'
import { Link, browserHistory } from 'react-router'

export default class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedLink: this.props.location.pathname,
      sideBarOpen: false,
    }

    browserHistory.listen((location) => {
      this.setState({
        selectedLink: location.pathname,
      })
    })
  }

  onTrial() {
    try {
      window.twttr.conversion.trackPid('nxi9r', {
        tw_sale_amount: 0,
        tw_order_quantity: 0,
      })
    } catch (e) {}

    setTimeout(() => {
      window.location =
        'https://releases.flagtimes.com/paddle/FlagTimes-latest.dmg'
    }, 500)
  }

  onBuy() {
    window.Paddle.Checkout.open({
      product: 514033,
      successCallback: (data) => {
        try {
          window.twttr.conversion.trackPid('nxi9r', {
            tw_sale_amount: data.checkout.prices.customer.total,
            tw_order_quantity: data.product.quantity,
          })
        } catch (e) {}
      },
    })
  }

  renderLinks(activeLink) {
    const titles = new Map()

    titles.set('/', '👋')
    titles.set('/beta', '')

    return titles.forEach((route, i) => {
      const { path } = route
      const title = titles.get(path)

      return (
        <Link to={path} key={i} style={{ order: i * 5 }}>
          <button className={path === activeLink ? 'inverted' : ''}>
            {title}
          </button>
        </Link>
      )
    })
  }

  render() {
    const { selectedLink } = this.state
    return (
      <nav>
        {this.renderLinks(selectedLink)}
        <button onClick={this.onBuy.bind(this)} style={{ order: 2 }}>
          🎉 Buy Now 🎉
        </button>
        <button onClick={this.onTrial.bind(this)} key="3" style={{ order: 3 }}>
          🤔 Download Trial 🤔
        </button>
      </nav>
    )
  }
}
