import React, { Component } from 'react'

export default class Form extends Component {

  constructor() {
    super()

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    let obj = {}

    obj[event.target.name] = event.target.value

    this.props.updateState(obj)
  }

  render() {
    return (
      <section className="details">
        <input
          autoComplete="false"
          pattern=".{1,}"
          required
          type="text"
          name="name"
          onChange={this.handleChange}
          placeholder="name"
        />
        <input
          autoComplete="false"
          pattern=".{1,}"
          required
          type="text"
          name="os"
          onChange={this.handleChange}
          placeholder="macOS version"
        />
        <input
          autoComplete="false"
          pattern=".{5,}"
          required
          type="email"
          name="email"
          onChange={this.handleChange}
          placeholder="email"
        />
      </section>

    )
  }
}