import React, { Component } from 'react'
import { Router, Route, browserHistory, IndexRoute } from 'react-router'

import Header from './components/header'
import Flags from './components/flags'
import Home from './components/home'
import Support from './components/support'
import Beta from './components/beta.js'
import Thanks from './components/thanks'
import Footer from './components/footer'

import { Covid19Header } from './components/covid19'

require('./stylesheets/main.scss')

class Container extends Component {


  render() {
    return (
      <div className="container">
        <Covid19Header />
        <Header {...this.props} />
        <main>
          { this.props.children }
        </main>
        <Footer />
      </div>
    )
  }
}

class App extends Component {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}  history={browserHistory}>
        <Route path="/" component={Container}>
          <IndexRoute component={Home} />
          <Route title="support" path="/support"  component={Support} />
          <Route title="thanks" path="/thanks"    component={Thanks} />
        </Route>
      </Router>
    )
  }
}

export default App
