import React, { Component } from 'react'
import Form from './beta.form'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const statusText = new Map()

statusText.set(-1, 'Your form contains errors. :(')
statusText.set(0, 'Send.')
statusText.set(1, 'Sending..')
statusText.set(2, 'Sent!')
statusText.set(3, 'Send again?')

export default class Beta extends Component {
  constructor() {
    super()

    this.state = {
      status: 0,
      name: false,
      OS: false,
      email: false
    }

    this.sendApplication = this.sendApplication.bind(this)
    this.updateState = this.updateState.bind(this)
  }

  updateState(data) {
    this.setState(data)
  }

  async sendApplication(event) {
    const { name, email, status } = this.state

    if (event.target.disabled || status === 3 ) window.location.reload()
    if (event.target.disabled || status !== 0 ) return

    this.setState({ status: 1 })

    const response = await fetch('https://hooks.zapier.com/hooks/catch/839044/mf8zyz', {
      method: 'POST',
      body: JSON.stringify({
        when: ((new Date())),
        name,
        email
      })
    })

    this.setState({ status: response.status === 200 ? 2 : -1 })
    setTimeout(() => {
      this.setState({ status: 3 })
    }, 1000)
  }

  componentDidMount() {}

  render() {
    const { name, email, status } = this.state
    const hasDetails = [name, email].filter(o => { return !!o }).length === 2

    return (
      <ReactCSSTransitionGroup
        transitionName="FadeIn"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnterTimeout={750}
        transitionLeaveTimeout={750}
        component="section"
      >

      <h1 style={{textAlign: "center"}}>Coming soon. </h1>
      <h2 style={{textAlign: "center"}}>Waiting for Apple's approval. 😴</h2>
      <a href="https://flagtimes.typeform.com/to/DXxl3E"><h3 style={{textAlign: "center"}}>Can't wait? <b>Join the closed beta!</b> </h3></a>


      </ReactCSSTransitionGroup>
    )
  }
}
