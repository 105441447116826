import React, { Component } from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider';

const SliderWithTooltip = createSliderWithTooltip(Slider);

function secondsFormatter(v) {
  return `${v} seconds`;
}

function timezonesFormatter(v) {
  return `${v} timezones`;
}

function yearsFormatter(v) {
  return `${v} years`;
}

export class SavedDays extends Component {
  constructor() {
    super()

    this.state = {
      seconds: 60,
      timezones: 4,
      years: 15
    }

    this.updateState = this.updateState.bind(this)
  }

  updateState(key, val) {
    this.setState({[key]: val })
  }
  render() {
    const { seconds, years, timezones } = this.state
    const days = ((timezones * seconds * years * 365) / (60 * 60) / 24).toFixed(2)
    const tSuffix = timezones == 1 ? '' : 's'
    const dSuffix = days < 1 ? '' : 's'
    const ySuffix = years == 1 ? '' : 's'

    return (
      <section className="days-saved">
        <h1 className="knee">How much time will you save?</h1>
        <p>Let's say you get distracted for about {seconds} seconds a day per timezone thinking about time while at your computer.</p>

        <h3 className="knee">{seconds} seconds per day</h3>
         <Slider
          dots
          step={20}
          defaultValue={seconds}
          min={20}
          max={120}
          tipFormatter={secondsFormatter}
          tipProps={{ overlayClassName: 'foo' }}
          onChange={(v) => this.updateState('seconds', v)}
        />
        <h3 className="knee">{timezones} Timezone{`${timezones == 1 ? '' : 's'}`}</h3>
        <Slider
          dots
          step={1}
          defaultValue={timezones}
          min={1}
          max={7}
          tipFormatter={timezonesFormatter}
          tipProps={{ overlayClassName: 'foo' }}
          onChange={(v) => this.updateState('timezones', v)}
        />
        <h3 className="knee">{years} Year{`${years == 1 ? '' : 's'}`}</h3>
        <Slider
          defaultValue={years}
          min={1}
          max={50}
          tipFormatter={yearsFormatter}
          tipProps={{ overlayClassName: 'foo' }}
          onChange={(v) => this.updateState('years', v)}
        />
        <h2 className="knee">{timezones} timezone{tSuffix} * {seconds} seconds * {years} year{ySuffix} = <u>{days} day{dSuffix}!</u></h2>
      </section>
    )
  }
}