import React, { Component } from 'react'
import { Link, browserHistory } from 'react-router'
import Navigation from './header.navigation'

import { facebook, twitter } from './icons'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class Header extends Component {

   constructor(props) {
    super(props)

    this.state = {
      sideBarOpen: false
    }

    this.toggleSidebar = this.toggleSidebar.bind(this)

    browserHistory.listen( location =>  {
     this.setState({
      sideBarOpen: false
     })
     document.body.setAttribute('data-sidebar-open', false)
    })
  }

  toggleSidebar() {
    const { sideBarOpen } = this.state

    this.setState({
      sideBarOpen: !sideBarOpen
    })

    document.body.setAttribute('data-sidebar-open', !sideBarOpen)
  }

  render() {
    const { sideBarOpen } = this.state

    return (
      <ReactCSSTransitionGroup
        transitionName="page"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnterTimeout={750}
        transitionLeaveTimeout={750}
        component="header"
        style={{textAlign:'center'}}
      >
        <nav>
          <figure className="logo">
            <Link to={'/'} className="no-border">
              <img className="logo" src={require('../assets/images/logo-text.png')}/>
            </Link>
          </figure>

          <ul>
            <li>
              <Link to={'/support'}>F.A.Q.</Link>
            </li>
            <li>
              <a className="no-border" href="https://twitter.com/flagtimes">{ twitter }</a>
            </li>
            <li>
              <a className="no-border" href="https://facebook.com/flagtimes">{ facebook }</a>
            </li>
          </ul>

        </nav>

        {/* <Navigation {...this.props} /> */ }
        <button className="sidebar-button" onClick={this.toggleSidebar}> {sideBarOpen ? '𝗫' : '☰'} </button>
      </ReactCSSTransitionGroup>
    )
  }
}