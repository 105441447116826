import React, { Component } from 'react'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class Thanks extends Component {
    render() {
      return (
        <ReactCSSTransitionGroup
          transitionName="FadeIn"
          transitionAppear={true}
          transitionAppearTimeout={1000}
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
          className="thanks"
          component="section"
          style={{textAlign: 'center'}}
        >

          <h1 className="knee">Thank you for supporting FlagTimes!</h1>
          <h3 >You should receive an email shortly with a download link and your license. 🎉</h3>
          <p> or <a href="https://releases.flagtimes.com/paddle/FlagTimes-latest.dmg">download now</a></p>

        </ReactCSSTransitionGroup>
      )
  }
}