import React, { Component } from 'react'
import { Link } from 'react-router'

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <h3> made by <a href="https://twitter.com/reimertz">@reimertz</a></h3>
      </footer>
    )
  }
}