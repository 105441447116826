import React from 'react'

export const Features = () => (
  <section className="features">
    <h1 className="knee">Features</h1>
    <ul className="cards">
      <li>
          <p className="emoji">🎌</p>
        <h2  className="knee">Multiple Flags</h2>
        <p>You might work with people from multiple countries. Don't worry, FlagTimes support multiple flags!</p>
      </li>
      <li>
        <p className="emoji">🛠</p>
        <h2  className="knee">Customizable</h2>
        <p>FlagTimes comes with some settings such as am/pm time, auto-start on login.</p>
      </li>
      <li>
        <p className="emoji">🙏</p>
        <h2  className="knee">Trustworthy</h2>
        <p>FlagTimes is based on Apples own clock. So if you trust your computers time, you can trust FlagTimes! </p>
      </li>
      <li>
        <p className="emoji">🔋</p>
        <h2  className="knee">Battery safe</h2>
        <p>I hope you will run FlagTimes all the time so I made it super efficient. </p>
      </li>
      {/*<li>
        <p className="emoji">🌍</p>
        <h2  className="knee">All Countries</h2>
        <p>Includes all the countries and major cities in the world, just to make it a little bit easier.</p>
      </li>*/}
    </ul>
  </section>
)