import React, { Component } from 'react'
import { browserHistory, Link } from 'react-router'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import { slack } from './icons'
import { SavedDays } from './savedDays'
import { Features } from './features'

export default class Home extends Component {
  onTrial() {
    try {
      window.twttr.conversion.trackPid('nxia1', {
        tw_sale_amount: 0,
        tw_order_quantity: 0,
      })
    } catch (e) {}

    setTimeout(() => {
      window.location =
        'https://releases.flagtimes.com/paddle/FlagTimes-latest.dmg'
    }, 500)
  }

  onBuy() {
    window.Paddle.Checkout.open({
      product: 514033,
      successCallback: (data) => {
        try {
          window.twttr.conversion.trackPid('nxia1', {
            tw_sale_amount: data.checkout.prices.customer.total,
            tw_order_quantity: data.product.quantity,
          })
        } catch (e) {
        } finally {
          browserHistory.push('/thanks')
        }
      },
    })
  }

  renderButtons(headerTitle = false) {
    return (
      <section className="download first">
        {headerTitle && (
          <h1 className="knee" style={{ width: '100%' }}>
            {headerTitle}
          </h1>
        )}
        <a
          href="https://itunes.apple.com/us/app/flagtimes-never-be-confused-about-time-zones/id1234952668"
          style={{
            display: 'inline-block',
            margin: '10px',
            borderBottom: 'none',
          }}
        >
          <img
            src={require('../assets/images/mac_store_badge.svg')}
            style={{ verticalAlign: 'middle', height: 50 }}
          />
        </a>
        <p style={{ minWidth: 30 }}>or</p>
        <button
          className="purple"
          onClick={this.onBuy.bind(this)}
          style={{ margin: '10px' }}
        >
          Download DMG
        </button>

        <p style={{ minWidth: '100%' }}>Requires OS X 10.11 or later</p>
      </section>
    )
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="FadeIn"
        transitionAppear={true}
        transitionAppearTimeout={1000}
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={1000}
        className="home"
        component="section"
      >
        <section className="demo">
          <h2 className="knee never">
            The macOS timezone app for anyone working in multiple timezones.
          </h2>
          <img src={require('../assets/images/demo_final.gif')} />
          <h2 className="knee never-bottom">
            Never be confused about timezones again.
          </h2>
        </section>

        {this.renderButtons()}

        <Features />

        <SavedDays />

        {this.renderButtons('What are you waiting for?')}
      </ReactCSSTransitionGroup>
    )
  }
}
