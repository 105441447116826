import React from 'react'

export const Covid19Header = () => {

  return (
    <div style={{width: '100vw',  backgroundColor: 'rgba(0,0,0, 0.25)', textAlign: 'center'}}>
      <h1 style={{paddingLeft: 50, paddingRight: 50}}>Now free to easen the ongoing pandemic</h1>
      <h4 style={{paddingLeft: 50, paddingRight: 50}}>I know there is a lot of people working remote and with collueges all over the world.</h4>
      <h4 style={{paddingLeft: 50, paddingRight: 50}}>I want to contribute and is therefore making <u>FlagTimes free until things calm down.</u></h4>
      <h4> take care of each other // Piérre</h4>
    </div>
  )
}