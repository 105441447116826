import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class Support extends Component {

  componentDidMount() {
    const hash = window.location.hash.replace('#','')
    const element = document.getElementById(hash)

    if (element) {
      element.setAttribute('open', '')
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionName="FadeIn"
        transitionAppear={true}
        transitionAppearTimeout={1000}
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={1000}
        component="section"
      >
        <h1 style={{textAlign: "center"}}>Support / FAQ</h1>
        <details id="enabled-flags">
          <summary>
            <b>How do I list enabled flags?</b>
          </summary>
          <p>Click on the enabled button.</p>
          <img style={{marginLeft: 10, maxWidth: 500}}src={require("../assets/images/help/enabled_2.png")} />
        </details>

        <details id="reposition-flags">
          <summary>
            <b>How do I reposition FlagTimes?</b>
          </summary>
          <p>CMD + move FlagTimes. <i>− only available for Sierra</i></p>
          <img style={{marginLeft: 10, maxWidth: 500}}src={require("../assets/images/help/cmd_move.png")} />
        </details>

        <details id="flag-order">
          <summary>
            <b>How are flags ordered?</b>
          </summary>
          <p>Flags are currently ordered using timezone location in increasing order. From left to right.</p>
        </details>

        <details id="city-timezone">
          <summary>
            <b>When I select a city, it shows up as a timezone?</b>
          </summary>
          <p>FlagTimes stores all selections as timezones, so when you a city, we find what timezone it's located in.</p>
        </details>

        <details id="multiple-tz-country">
          <summary>
            <b>How to add multi timezones within the same country?</b>
          </summary>
          <p>It sure does! Just click select multiple time zones within the same country. <i>Future update will enable support to customize labels</i></p>
          <img style={{marginLeft: 10, maxWidth: 500}}src={require("../assets/images/help/multiple_zones.png")} />
        </details>

        <details id="select-deselect">
          <summary>
            <b>How do I select/deselect something?</b>
          </summary>
          <p>Click on them in the list.</p>
        </details>

        <details id="system-clock">
          <summary>
            <b>What happens when I click on "system clock"?</b>
          </summary>
          <p>This will hide the regular clock that comes with macOS if you rather like to only use FlagTimes. Don't worry though, FlagTimes enables it again once being quit.</p>
        </details>

        <details id="am-pm">
          <summary>
            <b>What is "am/pm"?</b>
          </summary>
          <p>This is a something they use in United States to display time, a 12-hour clock. <a href="https://en.wikipedia.org/wiki/12-hour_clock" target="_blank">Read more here</a> </p>
        </details>

      </ReactCSSTransitionGroup>
    )
  }
}